export const PanelApiSettingKeys = [
  'virtual_enable',
  'disablePlans',
  'disableBoleto',
  'disableAccountCreation',
  'disableP2PTransfer',
  'posPairingEnable',
  'isBankingRollback',
  'disableBankAccountOperation',
  'allowBankAccountCreationFromDifferentTaxpayerId',
  'disableDash',
  'showAnticipationBanner',
  'showAdequacyPopup',
  'prepaymentByApiEnabled',
  'enableBillingPayment',
  'disableManualPayout',
  'disableAutomaticPayout',
  'disablebankAccountTransfer',
  'usersEnabledToReverseInstantTransactions'
]
