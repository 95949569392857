import { green, red, yellow } from 'src/Styles/settings/Constants'

const transactionDict = {
  succeeded: { label: 'Aprovada', color: green },
  canceled: { label: 'Cancelada', color: red },
  expired: { label: 'Expirada', color: red },
  pending: { label: 'Pendente', color: yellow },
  failed: { label: 'Falhada', color: red },
  new: { label: 'Nova', color: green },
  pre_authorized: { label: 'Pré-autorizada', color: yellow },
  reversed: { label: 'Revertida', color: red },
  refunded: { label: 'Estornada', color: red },
  dispute: { label: 'Em disputa', color: yellow },
  charged_back: { label: 'Chargeback', color: red }
}

export { transactionDict as TransactionStatus }
