import { green, red, yellow } from 'src/Styles/settings/Constants'

const transferDict = {
  succeeded: { label: 'Paga', color: green },
  confirmed: { label: 'Confirmada', color: green },
  pending: { label: 'Pendente', color: yellow },
  failed: { label: 'Falhada', color: red },
  canceled: { label: 'Cancelada', color: red },
  scheduled: { label: 'Agendada', color: yellow },
  pending_bank: { label: 'Pendente Banco', color: yellow }
}

export { transferDict as TransferStatus }
