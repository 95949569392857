export default {
  'marketplace_view': [
    { 'read': 'MarketPlaceData' },
    { 'read': 'MarketplaceAddress' }
  ],
  'marketplace_action': [
    { 'update': 'Marketplace' },
    { 'update': 'MarketplaceAddress' },
    { 'update': 'MarketplaceOwner' }
  ],
  'transaction_view': [
    { 'read': 'Transaction' },
    { 'create': 'CancelledLetter' }
  ],
  'transaction_action': [
    { 'create': 'Transaction' }
  ],
  'transfer_action': [
    { 'create': 'Transfer' }
  ],
  'export_view': [
    { 'download': 'Export' }
  ],
  'export_action': [
    { 'create': 'ExportTransaction' },
    { 'create': 'ExportTransfer' }
  ],
  'seller_view': [
    { 'read': 'SellerAddress' },
    { 'read': 'Sellers' }
  ],
  'seller_detail_view': [
    { 'read': 'SellerDetail' }
  ],
  'seller_action': [
    { 'update': 'Seller' },
    { 'create': 'Seller' },
    { 'delete': 'Seller' },
    { 'update': 'SellerAddress' }
  ],
  'seller_create': [
    { 'create': 'Seller' }
  ],
  'seller_maintenance': [
    { 'update': 'Seller' },
    { 'delete': 'Seller' },
    { 'update': 'SellerAddress' }
  ],
  'plan_view': [
    { 'read': 'Plan' }
  ],
  'plan_action': [
    { 'update': 'Plan' },
    { 'create': 'Plan' },
    { 'delete': 'Plan' }
  ],
  'api_key_view': [
    { 'read': 'ApiKeys' }
  ],
  'api_key_action': [
    { 'create': 'ApiKeys' }
  ],
  'user_view': [
    { 'read': 'User' }
  ],
  'user_action': [
    { 'update': 'User' },
    { 'create': 'User' },
    { 'delete': 'User' },
    { 'update': 'Password' }
  ],
  'bank_account_view': [
    { 'read': 'BankAccount' }
  ],
  'bank_account_action': [
    { 'create': 'BankAccount' },
    { 'delete': 'BankAccount' }
  ],
  'webhook_action': [
    { 'create': 'Webhook' }
  ],
  'customize_action': [
    { 'create': 'Custom' }
  ],
  'pos_action': [
    { 'pair': 'POS' }
  ],
  'kpi_view': [
    { 'read': 'KPI' }
  ],
  'markup_view': [
    { 'read': 'Markup' }
  ],
  'markup_action': [
    { 'create': 'Markup' }
  ],
  'anticipation_action': [
    { 'create': 'Anticipation' }
  ],
  'anticipation_view': [
    { 'read': 'Anticipation' }
  ],
  'featureFlag_view': [
    { 'read': 'FeatureFlag' }
  ],
  'featureFlag_action': [
    { 'create': 'FeatureFlag' }
  ]
}
