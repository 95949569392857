export default {
  'full_access_admin': ['*'],
  'customers': [
    'transaction_view',
    'transaction_action',
    'transfer_view',
    'transfer_action',
    'balance_view',
    'seller_view',
    'seller_detail_view',
    'seller_action',
    'plan_view',
    'plan_action',
    'export_view',
    'pos_action',
    'export_action',
    'user_view',
    'user_action',
    'document_action',
    'bank_account_view',
    'bank_account_action',
    'markup_view',
    'kpi_view'
  ],
  'full_access_operation': [
    'transaction_view',
    'transaction_action',
    'transfer_view',
    'transfer_action',
    'balance_view',
    'seller_view',
    'export_view',
    'export_action',
    'kpi_view',
    'anticipation_view'
  ],
  'full_access_configuration': [
    'marketplace_view',
    'marketplace_action',
    'bank_account_view',
    'bank_account_action',
    'webhook_view',
    'webhook_action',
    'api_key_view',
    'api_key_action',
    'seller_view',
    'seller_detail_view',
    'seller_action',
    'customize_view',
    'customize_action',
    'user_view',
    'user_action',
    'document_action'
  ],
  'full_visualization': [
    'marketplace_view',
    'transaction_view',
    'transfer_view',
    'balance_view',
    'seller_view',
    'seller_detail_view',
    'plan_view',
    'bank_account_view',
    'webhook_view',
    'api_key_view',
    'customize_view',
    'user_view',
    'kpi_view'
  ]
}
