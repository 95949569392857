export const ENTRY_MODE_DICT = {
  chip: { label: 'Presencial' },
  contactless_chip: { label: 'Presencial' },
  manually_keyed: { label: 'Online' },
  pix: { label: 'Pix' },
  barcode: { label: 'Boleto' },
  voucher: { label: 'voucher' },
  wallet: { label: 'wallet' },
  pending: { label: 'Online' },
  debit_manually_keyed: { label: 'Débito Online' },
  credit_manually_keyed: { label: 'Crédito Online' },
  contactless_tap: { label: 'Presencial' },
  magstripe: { label: 'Presencial' },
  data_only: { label: 'Online' }
}

export function getEntryMode (mode) {
  return ENTRY_MODE_DICT[mode] ? ENTRY_MODE_DICT[mode].label : mode
}
