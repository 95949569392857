import React from 'react'

export const ExportColumnsOptions = {
  transactions: [
    {
      label: <>Informações <strong>da Transação</strong></>,
      options: [
        { name: 'Código de autorização', selected: true, value: 'transaction_number' },
        { name: 'Data da venda', selected: true, value: 'created_at' },
        { name: 'Data prevista de recebimento', selected: true, value: 'expected_on' },
        { name: 'Descrição', selected: true, value: 'description' },
        { name: 'ID da transação', selected: true, value: 'id' },
        { name: 'ID de referência', selected: true, value: 'id_reference' },
        { name: 'ID do marketplace', selected: true, value: 'marketplace_id' },
        { name: 'Nome do marketplace', selected: true, value: 'marketplace_name' },
        { name: 'Número de parcelas', selected: true, value: 'number_of_installments' },
        { name: 'Plano de parcelamento', selected: true, value: 'mode' },
        { name: 'Status', selected: true, value: 'status' },
        { name: 'Motivo do cancelamento', selected: true, value: 'history' },
        { name: 'Modo de Captura', selected: true, value: 'entry_mode' },
        { name: 'Tipo de pagamento', selected: true, value: 'payment_type' },
        { name: 'Valor da venda', selected: true, value: 'original_amount' },
        { name: 'Valor capturado', selected: true, value: 'amount' },
        { name: 'Valor bruto', selected: true, value: 'receivable_amount' },
        { name: 'Valor líquido', selected: true, value: 'receivable_net_amount' },
        { name: 'Terminal', selected: true, value: 'terminal_serial_number' }
      ]
    },
    {
      label: <>Informações <strong>do Pix</strong></>,
      options: [
        { name: 'ID', selected: true, value: 'pix.id' },
        { name: 'Versão', selected: true, value: 'pix.version' },
        { name: 'Provedor', selected: true, value: 'pix.provider' },
        { name: 'Chave', selected: true, value: 'pix.key' },
        { name: 'Tipo de chave', selected: true, value: 'pix.key_type' },
        { name: 'Tipo', selected: true, value: 'pix.type' },
        { name: 'Texto do QRCode', selected: true, value: 'pix.qrcode_emv' },
        { name: 'Link', selected: true, value: 'pix.pix_link' },
        { name: 'Reutilizável', selected: true, value: 'pix.reusable' },
        { name: 'Habilita atualização', selected: true, value: 'pix.allow_update' },
        { name: 'Data de expiração', selected: true, value: 'pix.expiration_date' },
        { name: 'Data de criação', selected: true, value: 'pix.created_at' },
        { name: 'Data de atualização', selected: true, value: 'pix.updated_at' }
      ]
    },
    {
      label: <>Informações <strong>do Estabelecimento</strong></>,
      options: [
        { name: 'ID do estabelecimento', selected: true, value: 'on_behalf_of' },
        { name: 'Nome do estabelecimento', selected: true, value: 'seller_name' },
        { name: 'CPF/CNPJ', selected: true, value: 'seller_tax_document_number' }
      ]
    },
    {
      label: <>Informações <strong>do Cartão</strong></>,
      options: [
        { name: 'Bandeira', selected: true, value: 'payment_method.card_brand' },
        { name: 'Portador do cartão', selected: true, value: 'payment_method.holder_name' },
        { name: 'Primeiros 4 dígitos do cartão', selected: true, value: 'payment_method.first4_digits' },
        { name: 'Últimos 4 dígitos do cartão', selected: true, value: 'payment_method.last4Digits' }
      ]
    }
  ],
  transfers: [
    {
      label: <>Informações <strong>da Transação</strong></>,
      options: [
        { name: 'Data da venda', selected: true, value: 'transaction.created_at' },
        { name: 'Descrição', selected: true, value: 'description' },
        { name: 'ID da transação', selected: true, value: 'transaction_id' },
        { name: 'ID do Marketplace', selected: true, value: 'marketplace_id' },
        { name: 'Número da parcela', selected: true, value: 'installment' },
        { name: 'Plano de parcelamento', selected: true, value: 'plan_name' },
        { name: 'Status', selected: true, value: 'transfer.status_payment' },
        { name: 'Tipo de pagamento', selected: true, value: 'entry_description' },
        { name: 'Valor líquido', selected: true, value: 'entry_amount' },
        { name: 'Terminal', selected: true, value: 'terminal_serial_number' }
      ]
    },
    {
      label: <>Informações <strong>do Estabelecimento</strong></>,
      options: [
        { name: 'ID do estabelecimento', selected: true, value: 'seller_id' },
        { name: 'Nome do estabelecimento', selected: true, value: 'seller_name' },
        { name: 'CPF/CNPJ do estabelecimento', selected: true, value: 'tax_payer_id' }
      ]
    },
    {
      label: <>Informações <strong>de Pagamento</strong></>,
      options: [
        { name: 'Tipo de transferência', selected: true, value: 'transfer_type' },
        { name: 'Favorecido', selected: true, value: 'favorecido' },
        { name: 'Conta bancária', selected: true, value: 'account_number' },
        { name: 'Agência', selected: true, value: 'routing_number' },
        { name: 'Banco', selected: true, value: 'bank_name' },
        { name: 'Data da transferência', selected: true, value: 'created_at' },
        { name: 'Status da operação', selected: true, value: 'status_payment' },
        { name: 'Valor da transferência', selected: true, value: 'amount_payment' },
        { name: 'ID da transferência', selected: true, value: 'transfer_id' },
        { name: 'Código de autorização', selected: true, value: 'transfer_number' }
      ]
    }
  ],
  entries: [
    {
      label: <>Informações <strong>da Transação</strong></>,
      options: [
        { name: 'ID da transação', selected: true, value: 'transaction.id' },
        { name: 'Data da transação', selected: true, value: 'transaction.created_at' },
        { name: 'Status', selected: true, value: 'transfer.status_payment' },
        { name: 'Valor líquido', selected: true, value: 'transaction.net_amount' },
        { name: 'Valor bruto', selected: true, value: 'transaction.amount' },
        { name: 'Taxas', selected: true, value: 'transaction.fees' },
        { name: 'Tipo de pagamento', selected: true, value: 'transaction.payment_type' },
        { name: 'Número de parcelas', selected: true, value: 'transaction.number_of_installments' },
        { name: 'Plano de parcelamento', selected: true, value: 'transaction.plan_name' },
        { name: 'Número da transação', selected: true, value: 'transaction.number' },
        { name: 'Metadata', selected: true, value: 'transaction.metadata' },
        { name: 'ID de referência', selected: true, value: 'transaction.reference_id' },
        { name: 'Descrição', selected: true, value: 'transaction.description' },
        { name: 'Modo', selected: true, value: 'transaction.mode' },
        { name: 'Terminal', selected: true, value: 'transaction.terminal_serial_number' }
      ]
    },
    {
      label: <>Informações <strong>da Entrada</strong></>,
      options: [
        { name: 'ID da entrada', selected: true, value: 'id' },
        { name: 'Valor líquido', selected: true, value: 'net_amount' },
        { name: 'Valor bruto', selected: true, value: 'amount' },
        { name: 'Taxas', selected: true, value: 'fee' },
        { name: 'Valor Agendado', selected: true, value: 'blocked_amount' },
        { name: 'Tipo da Operação', selected: true, value: 'entry_type' },
        { name: 'Método', selected: true, value: 'entry_description' },
        { name: 'Data de lançamento', selected: true, value: 'date' },
        { name: 'NSU da operação', selected: true, value: 'nsu' },
        { name: 'Saldo', selected: true, value: 'current_balance' }
      ]
    },
    {
      label: <>Informações <strong>de Transferência</strong></>,
      options: [
        { name: 'CPF/CNPJ do pagador', selected: true, value: 'transfer.sender_ein' },
        { name: 'Nome do pagador', selected: true, value: 'transfer.sender_name' },
        { name: 'CPF/CNPJ do recebedor', selected: true, value: 'transfer.recipient_ein' },
        { name: 'Nome do recebedor', selected: true, value: 'transfer.recipient_name' },
        { name: 'Valor da transferência', selected: true, value: 'transfer.amount_payment' }
      ]
    },
    {
      label: <>Informações <strong>do Cartão</strong></>,
      options: [
        { name: 'Bandeira', selected: true, value: 'card.brand' },
        { name: 'Portador do cartão', selected: true, value: 'card.holder_name' },
        { name: 'Últimos 4 dígitos do cartão', selected: true, value: 'card.last4_digits' }
      ]
    },
    {
      label: <>Informações <strong>de Pagamento</strong></>,
      options: [
        { name: 'Favorecido', selected: true, value: 'bank.holder_name' },
        { name: 'Conta bancária', selected: true, value: 'bank.account_number' },
        { name: 'Agência', selected: true, value: 'bank.routing_number' },
        { name: 'Banco', selected: true, value: 'bank.name' },
        { name: 'Data prevista de pagamento', selected: true, value: 'expected_on' },
        { name: 'Data do pagamento', selected: true, value: 'paid_at' },
        { name: 'Valor do pagamento', selected: true, value: 'transfer.amount_payment' },
        { name: 'Número da parcela', selected: true, value: 'installment' },
        { name: 'Valor líquido da parcela', selected: true, value: 'net_amount_installment' },
        { name: 'ID da transferência', selected: true, value: 'transfer_id' }
      ]
    },
    {
      label: <>Informações <strong>do Estabelecimento</strong></>,
      options: [
        { name: 'Nome do estabelecimento', selected: true, value: 'seller_name' },
        { name: 'CPF/CNPJ do estabelecimento', selected: true, value: 'tax_payer_id' },
        { name: 'ID do estabelecimento', selected: true, value: 'seller_id' },
        { name: 'ID do Marketplace', selected: true, value: 'marketplace_id' }
      ]
    }
  ]
}

export const getAllColumns = type => {
  return ExportColumnsOptions[type].reduce((acc, item) => {
    const values = item.options.map(option => {
      return option.value
    })
    return [...acc, ...values]
  }, [])
}

export const getAllColumnsByType = type => {
  return ExportColumnsOptions[type]
}
