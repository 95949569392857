import './Styles/Status.scss'

import { gray, green, yellow, red } from 'src/Styles/settings/Constants'

export const statusLabels = {
  averageTicket: { label: 'Ticket Médio' },
  active: { label: 'Ativo', color: green },
  block: { label: 'Bloqueio', color: red },
  block_refunded: { label: 'Desbloqueio', color: gray },
  block_sisbajud: { label: 'Bloqueio SISBAJUD', color: red },
  block_refunded_sisbajud: { label: 'Desbloqueio SISBAJUD', color: gray },
  billing_payment: { label: 'Pagamento de Conta', color: gray },
  canceled: { label: 'Cancelada', color: red },
  denied: { label: 'Negado', color: red },
  deleted: { label: 'Deletado', color: red },
  expired: { label: 'Expirada', color: red },
  disabled: { label: 'Inativo', color: red },
  dispute: { label: 'Disputa', color: yellow },
  disputed: { label: 'Disputa', color: yellow },
  dispute_succeeded: { label: 'Disputa ganha', color: green },
  disputed_succeeded: { label: 'Disputa ganha', color: green },
  enabled: { label: 'Habilitado', color: green },
  failed: { label: 'Falhada', color: red },
  new: { label: 'Novo', color: green },
  paid: { label: 'Pago', color: green },
  pending: { label: 'Pendente', color: yellow },
  pre_authorized: { label: 'Pré-autorizado', color: yellow },
  pre_authorization: { label: 'Pré-autorizada', color: yellow },
  pre_authorization_succeeded: { label: 'Pré-autorizada', color: yellow },
  pre_authorization_failed: { label: 'Falha na pré-autorização', color: red },
  authorization: { label: 'Autorização', color: gray },
  authorization_new: { label: 'Nova' },
  authorization_succeeded: { label: 'Autorizada', color: green },
  authorization_pending: { label: 'Autorização pendente' },
  authorization_failed: { label: 'Falha na captura', color: red },
  authorization_delayed: { label: 'Atraso na captura' },
  authorization_expired: { label: 'Autorização expirada' },
  capture: { label: 'Nova', color: gray },
  capture_new: { label: 'Nova' },
  capture_succeeded: { label: 'Aprovada', color: green },
  capture_partial_succeeded: { label: 'Aprovada', color: green },
  capture_pending: { label: 'Captura pendente', color: yellow },
  capture_failed: { label: 'Falha na captura', color: red },
  capture_delayed: { label: 'Atraso na captura' },
  capture_expired: { label: 'Captura expirada' },
  created: { label: 'Criada', color: gray },
  created_succeeded: { label: 'Criada', color: green },
  refunded: { label: 'Estornado', color: red },
  refund: { label: 'Estornada', color: red },
  refund_failed: { label: 'Falha no estorno', color: red },
  reversed: { label: 'Revertida', color: red },
  succeeded: { label: 'Aprovada', color: green },
  transfer: { label: 'Transferências' },
  transfer_refunded: { label: 'Transferências estornadas' },
  charge: { label: 'Vendas' },
  charge_refunded: { label: 'Vendas estornadas' },
  deposit: { label: 'Depósitos' },
  deposit_refunded: { label: 'Depósitos estornados' },
  charged_back: { label: 'Chargeback', color: red },
  chargedBack: { label: 'Chargeback', color: red },
  approved: { label: 'Aprovada', color: green },
  rejected: { label: 'Recusada', color: red },
  void_reversal: { label: 'Cancelamento revertido', color: red },
  void: { label: 'Cancelamento total', color: red },
  void_succeeded: { label: 'Cancelamento total', color: red },
  void_partial_succeeded: { label: 'Cancelamento parcial', color: red },
  void_partial_failed: { label: 'Falha no cancelamento parcial', color: red },
  void_partial_pending: { label: 'Cancelamento parcial pendente', color: red },
  void_pending: { label: 'Cancelamento pendente', color: red },
  void_failed: { label: 'Falha no cancelamento', color: red },
  void_delayed: { label: 'Atraso no cancelamento', color: red },
  void_expired: { label: 'Cancelamento expirado', color: red },
  void_confirmation: { label: 'Cancelamento', color: red },
  void_confirmation_succeeded: { label: 'Cancelamento', color: red },
  void_confirmation_pending: { label: 'Cancelamento pendente', color: red },
  void_confirmation_failed: { label: 'Falha no cancelamento', color: red },
  void_confirmation_delayed: { label: 'Atraso no cancelamento', color: red },
  void_confirmation_expired: { label: 'Cancelamento expirado', color: red },
  partial_void: { label: 'Cancelamento parcial', color: red },
  reversal: { label: 'Revertida', color: red },
  expiration: { label: 'Expirada', color: red },
  adjustment: { label: 'Ajuste' },
  scheduled: { label: 'Agendado', color: yellow },
  proposal: { label: 'Pendente de pagamento', color: yellow },
  ted: { label: 'TED' },
  ted_refunded: { label: 'TED estornada' },
  risk_analysis: { label: 'Analise de Risco', color: red }
}

export function getStatusLabel(status) {
  return statusLabels[status]
    ? statusLabels[status]
    : { label: status, color: gray }
}
