export const AccountTypes = [
  {
    value: 'checking',
    label: 'Conta Corrente'
  },
  {
    value: 'savings',
    label: 'Conta Poupança'
  }
]
