// Check authentication
export const CHECK_AUTH_USER = 'CHECK_AUTH_USER'
export const CHECK_AUTH_USER_ERROR = 'CHECK_AUTH_USER_ERROR'

export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST'
export const CHECK_TOKEN_REQUESTED = 'CHECK_TOKEN_REQUESTED'
export const CONFIRM_USER_REQUEST = 'CONFIRM_USER_REQUEST'
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS'
export const CONFIRM_USER_ERROR = 'CONFIRM_USER_ERROR'
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS'
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR'

export const MFA_REQUEST = 'MFA_REQUEST'
export const MFA_SUCCESS = 'MFA_SUCCESS'
export const MFA_ERROR = 'MFA_ERROR'
export const RESET_MFA_ERROR = 'RESET_MFA_ERROR'

// Banks
export const GET_BANKS_REQUEST = 'GET_BANKS_REQUEST'
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS'
export const GET_BANKS_FAILURE = 'GET_BANKS_FAILURE'

// Marketplace
export const MARKETPLACE_SLUG_REQUEST = 'MARKETPLACE_SLUG_REQUEST'
export const MARKETPLACE_SLUG_ERROR = 'MARKETPLACE_SLUG_ERROR'
export const MARKETPLACE_REQUEST = 'MARKETPLACE_REQUEST'
export const MARKETPLACE_SUCCESS = 'MARKETPLACE_SUCCESS'
export const MARKETPLACE_ERROR = 'MARKETPLACE_ERROR'

// Resume
export const RESUME_REQUEST = 'RESUME_REQUEST'
export const RESUME_SUCCESS = 'RESUME_SUCCESS'
export const RESUME_ERROR = 'RESUME_ERROR'
export const RESUME_TABLE_REQUEST = 'RESUME_TABLE_REQUEST'
export const RESUME_TABLE_SUCCESS = 'RESUME_TABLE_SUCCESS'
export const RESUME_TABLE_ERROR = 'RESUME_TABLE_ERROR'
export const RESET_RESUME = 'RESET_RESUME'

// Api Settings
export const API_SETTINGS_REQUEST = 'API_SETTINGS_REQUEST'
export const API_SETTINGS_SUCCESS = 'API_SETTINGS_SUCCESS'
export const API_SETTINGS_ERROR = 'API_SETTINGS_ERROR'

// Whitelabel
export const RESET_WHITELABEL = 'RESET_WHITELABEL'

// Auth
export const CHECK_CREDENTIALS_REQUEST = 'CHECK_CREDENTIALS_REQUEST'
export const CHECK_CREDENTIALS_ERROR = 'CHECK_CREDENTIALS_ERROR'
export const CHECK_CREDENTIALS_SUCCESS = 'CHECK_CREDENTIALS_SUCCESS'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const INVALIDATE_CONNECTED_ACCOUNT_TOKEN =
  'INVALIDATE_CONNECTED_ACCOUNT_TOKEN'

// Slugs
export const GET_SLUG_REQUEST = 'GET_SLUG_REQUEST'
export const GET_SLUG_SUCCESS = 'GET_SLUG_SUCCESS'
export const GET_SLUG_ERROR = 'GET_SLUG_ERROR'

// Reset Password
export const RECOVER_ACCESS_REQUEST = 'RECOVER_ACCESS_REQUEST'
export const RECOVER_ACCESS_SUCCESS = 'RECOVER_ACCESS_SUCCESS'
export const RECOVER_ACCESS_ERROR = 'RECOVER_ACCESS_ERROR'

export const RESET_AUTH = 'RESET_AUTH'
export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR'

// User Permission
export const PERMISSION_REQUEST = 'PERMISSION_REQUEST'
export const PERMISSION_SUCCESS = 'PERMISSION_SUCCESS'
export const PERMISSION_ERROR = 'PERMISSION_ERROR'

export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST'
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS'
export const UPDATE_PERMISSION_ERROR = 'UPDATE_PERMISSION_ERROR'

// User Market PLace
export const USER_MARKETPLACE_REQUEST = 'USER_MARKETPLACE_REQUEST'
export const USER_MARKETPLACE_SUCCESS = 'USER_MARKETPLACE_SUCCESS'
export const USER_MARKETPLACE_ERROR = 'USER_MARKETPLACE_ERROR'

// User Market PLace
export const MARKETPLACE_CONTEXT_SELECT = 'MARKETPLACE_CONTEXT_SELECT'

// Select Marketplace Context
export const MARKETPLACES_ACCESS_REQUEST = 'MARKETPLACES_ACCESS_REQUEST'
export const MARKETPLACES_ACCESS_SUCCESS = 'MARKETPLACES_ACCESS_SUCCESS'
export const MARKETPLACES_ACCESS_ERROR = 'MARKETPLACES_ACCESS_ERROR'
export const RESET_CONTEXT = 'RESET_CONTEXT'

// Manager User
export const CHECK_REGISTER_SUCCESS = 'CHECK_USER_REGISTER_SUCCESS'
export const CHECK_REGISTER_REQUEST = 'CHECK_USER_REGISTER_REQUEST'
export const CHECK_REGISTER_ERROR = 'CHECK_USER_REGISTER_ERROR'

export const LIST_USERS_REQUEST = 'LIST_USERS_REQUEST'
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS'
export const LIST_USERS_ERROR = 'LIST_USERS_ERROR'

export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'
export const USER_DETAIL_ERROR = 'USER_DETAIL_ERROR'

export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST'
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS'
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const UPDATE_PASS_REQUEST = 'UPDATE_PASS_REQUEST'
export const UPDATE_PASS_SUCCESS = 'UPDATE_PASS_SUCCESS'
export const UPDATE_PASS_ERROR = 'UPDATE_PASS_ERROR'

export const CONNECT_USER_REQUEST = 'CONNECT_USER_REQUEST'
export const CONNECT_USER_SUCCESS = 'CONNECT_USER_SUCCESS'
export const CONNECT_USER_ERROR = 'CONNECT_USER_ERROR'

export const DISCONNECT_USER_REQUEST = 'DISCONNECT_USER_REQUEST'
export const DISCONNECT_USER_SUCCESS = 'DISCONNECT_USER_SUCCESS'
export const DISCONNECT_USER_ERROR = 'DISCONNECT_USER_ERROR'

export const DELETE_USER_PROFILE_REQUEST = 'DELETE_USER_PROFILE_REQUEST'
export const DELETE_USER_PROFILE_SUCCESS = 'DELETE_USER_PROFILE_SUCCESS'
export const DELETE_USER_PROFILE_ERROR = 'DELETE_USER_PROFILE_ERROR'

export const RESET_MANAGER_USER = 'RESET_MANAGER_USER'
export const RESET_UPDATE_USER_PASS = 'RESET_UPDATE_USER_PASS'

export const RESET_USER = 'RESET_USER'

// Bank Accounts
export const RESET_BANK_ACCOUNTS = 'RESET_BANK_ACCOUNTS'

export const GET_ACTIVE_BANK_ACCOUNT_REQUEST = 'GET_ACTIVE_BANK_ACCOUNT_REQUEST'
export const GET_ACTIVE_BANK_ACCOUNT_SUCCESS = 'GET_ACTIVE_BANK_ACCOUNT_SUCCESS'
export const GET_ACTIVE_BANK_ACCOUNT_ERROR = 'GET_ACTIVE_BANK_ACCOUNT_ERROR'

export const LIST_BANK_ACCOUNTS_REQUEST = 'LIST_BANK_ACCOUNTS_REQUEST'
export const LIST_BANK_ACCOUNTS_SUCCESS = 'LIST_BANK_ACCOUNTS_SUCCESS'
export const LIST_BANK_ACCOUNTS_ERROR = 'LIST_BANK_ACCOUNTS_ERROR'

export const ACTIVATE_BANK_ACCOUNT_REQUEST = 'ACTIVATE_BANK_ACCOUNT_REQUEST'
export const ACTIVATE_BANK_ACCOUNT_SUCCESS = 'ACTIVATE_BANK_ACCOUNT_SUCCESS'
export const ACTIVATE_BANK_ACCOUNT_ERROR = 'ACTIVATE_BANK_ACCOUNT_ERROR'

export const GET_DEFAULT_BANK_RECEIVER_REQUEST =
  'GET_DEFAULT_BANK_RECEIVER_REQUEST'
export const GET_DEFAULT_BANK_RECEIVER_SUCCESS =
  'GET_DEFAULT_BANK_RECEIVER_SUCCESS'
export const GET_DEFAULT_BANK_RECEIVER_ERROR = 'GET_DEFAULT_BANK_RECEIVER_ERROR'

export const SET_DEFAULT_BANK_RECEIVER_REQUEST =
  'SET_DEFAULT_BANK_RECEIVER_REQUEST'
export const SET_DEFAULT_BANK_RECEIVER_SUCCESS =
  'SET_DEFAULT_BANK_RECEIVER_SUCCESS'
export const SET_DEFAULT_BANK_RECEIVER_ERROR = 'SET_DEFAULT_BANK_RECEIVER_ERROR'

export const CREATE_BANK_ACCOUNT_REQUEST = 'CREATE_BANK_ACCOUNT_REQUEST'
export const CREATE_BANK_ACCOUNT_SUCCESS = 'CREATE_BANK_ACCOUNT_SUCCESS'
export const CREATE_BANK_ACCOUNT_ERROR = 'CREATE_BANK_ACCOUNT_ERROR'

export const CREATE_SELLER_BANK_ACCOUNT_REQUEST =
  'CREATE_SELLER_BANK_ACCOUNT_REQUEST'
export const CREATE_SELLER_BANK_ACCOUNT_SUCCESS =
  'CREATE_SELLER_BANK_ACCOUNT_SUCCESS'
export const CREATE_SELLER_BANK_ACCOUNT_ERROR =
  'CREATE_SELLER_BANK_ACCOUNT_ERROR'

export const ASSOCIATE_BANK_ACCOUNT_REQUEST = 'ASSOCIATE_BANK_ACCOUNT_REQUEST'
export const ASSOCIATE_BANK_ACCOUNT_SUCCESS = 'ASSOCIATE_BANK_ACCOUNT_SUCCESS'
export const ASSOCIATE_BANK_ACCOUNT_ERROR = 'ASSOCIATE_BANK_ACCOUNT_ERROR'

export const DETAIL_BANK_ACCOUNT_REQUEST = 'DETAIL_BANK_ACCOUNT_REQUEST'
export const DETAIL_BANK_ACCOUNT_SUCCESS = 'DETAIL_BANK_ACCOUNT_SUCCESS'
export const DETAIL_BANK_ACCOUNT_ERROR = 'DETAIL_BANK_ACCOUNT_ERROR'

export const UPDATE_BANK_ACCOUNT_REQUEST = 'UPDATE_BANK_ACCOUNT_REQUEST'
export const UPDATE_BANK_ACCOUNT_SUCCESS = 'UPDATE_BANK_ACCOUNT_SUCCESS'
export const UPDATE_BANK_ACCOUNT_ERROR = 'UPDATE_BANK_ACCOUNT_ERROR'

export const DELETE_BANK_ACCOUNT_REQUEST = 'DELETE_BANK_ACCOUNT_REQUEST'
export const DELETE_BANK_ACCOUNT_SUCCESS = 'DELETE_BANK_ACCOUNT_SUCCESS'
export const DELETE_BANK_ACCOUNT_ERROR = 'DELETE_BANK_ACCOUNT_ERROR'

export const UPDATE_RECEIVING_POLICY_REQUEST = 'UPDATE_RECEIVE_POLICY_REQUEST'
export const UPDATE_RECEIVING_POLICY_SUCCESS = 'UPDATE_RECEIVE_POLICY_SUCCESS'
export const UPDATE_RECEIVING_POLICY_ERROR = 'UPDATE_RECEIVE_POLICY_ERROR'

export const GET_RECEIVING_POLICY_REQUEST = 'GET_RECEIVING_POLICY_REQUEST'
export const GET_RECEIVING_POLICY_SUCCESS = 'GET_RECEIVING_POLICY_SUCCESS'
export const GET_RECEIVING_POLICY_ERROR = 'GET_RECEIVING_POLICY_ERROR'

export const RESET_RECIPIENT_MEANS = 'RESET_RECIPIENT_MEANS'

export const BLOCK_CARD_REQUEST = 'BLOCK_CARD_REQUEST'
export const BLOCK_CARD_SUCCESS = 'BLOCK_CARD_SUCCESS'
export const BLOCK_CARD_ERROR = 'BLOCK_CARD_ERROR'

export const CHARGE_CARD_REQUEST = 'CHARGE_CARD_REQUEST'
export const CHARGE_CARD_SUCCESS = 'CHARGE_CARD_SUCCESS'
export const CHARGE_CARD_ERROR = 'CHARGE_CARD_ERROR'
export const NEW_CHARGE = 'NEW_CHARGE'

// Transactions
export const LIST_TRANSACTIONS_REQUEST = 'LIST_TRANSACTIONS_REQUEST'
export const LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS'
export const LIST_TRANSACTIONS_ERROR = 'LIST_TRANSACTIONS_ERROR'
export const GET_TRANSACTION_BY_ID_REQUEST = 'GET_TRANSACTION_BY_ID_REQUEST'
export const GET_TRANSACTION_BY_ID_SUCCESS = 'GET_TRANSACTION_BY_ID_SUCCESS'
export const GET_TRANSACTION_BY_ID_ERROR = 'GET_TRANSACTION_BY_ID_ERROR'
export const TRANSACTION_DETAIL_REQUEST = 'TRANSACTION_DETAIL_REQUEST'
export const TRANSACTION_DETAIL_SUCCESS = 'TRANSACTION_DETAIL_SUCCESS'
export const TRANSACTION_DETAIL_ERROR = 'TRANSACTION_DETAIL_ERROR'
export const GET_TRANSACTION_SELLER_DETAILS_REQUEST =
  'GET_TRANSACTION_SELLER_DETAILS_REQUEST'
export const GET_TRANSACTION_SELLER_DETAILS_SUCCESS =
  'GET_TRANSACTION_SELLER_DETAILS_SUCCESS'
export const GET_TRANSACTION_SELLER_DETAILS_ERROR =
  'GET_TRANSACTION_SELLER_DETAILS_ERROR'
export const GET_TRANSACTION_BUYER_DETAILS_REQUEST =
  'GET_TRANSACTION_BUYER_DETAILS_REQUEST'
export const GET_TRANSACTION_BUYER_DETAILS_SUCCESS =
  'GET_TRANSACTION_BUYER_DETAILS_SUCCESS'
export const GET_TRANSACTION_BUYER_DETAILS_ERROR =
  'GET_TRANSACTION_BUYER_DETAILS_ERROR'
export const VOID_TRANSACTION_REQUEST = 'VOID_TRANSACTION_REQUEST'
export const VOID_TRANSACTION_SUCCESS = 'VOID_TRANSACTION_SUCCESS'
export const VOID_TRANSACTION_ERROR = 'VOID_TRANSACTION_ERROR'
export const CANCEL_BOLETO_TRANSACTION_REQUEST =
  'CANCEL_BOLETO_TRANSACTION_REQUEST'
export const CANCEL_BOLETO_TRANSACTION_SUCCESS =
  'CANCEL_BOLETO_TRANSACTION_SUCCESS'
export const CANCEL_BOLETO_TRANSACTION_ERROR = 'CANCEL_BOLETO_TRANSACTION_ERROR'
export const CAPTURE_TRANSACTION_REQUEST = 'CAPTURE_TRANSACTION_REQUEST'
export const CAPTURE_TRANSACTION_SUCCESS = 'CAPTURE_TRANSACTION_SUCCESS'
export const CAPTURE_TRANSACTION_ERROR = 'CAPTURE_TRANSACTION_ERROR'
export const RESET_TRANSACTION = 'RESET_TRANSACTION'
export const RESET_TRANSACTION_ACTIONS = 'RESET_TRANSACTION_ACTIONS'
export const GET_TRANSACTION_CANCELLED_LETTER_REQUEST =
  'GET_TRANSACTION_CANCELLED_LETTER_REQUEST'
export const GET_TRANSACTION_CANCELLED_LETTER_SUCCESS =
  'GET_TRANSACTION_CANCELLED_LETTER_SUCCESS'
export const GET_TRANSACTION_CANCELLED_LETTER_ERROR =
  'GET_TRANSACTION_CANCELLED_LETTER_ERROR'

// Transfers
export const LIST_TRANSFERS_REQUEST = 'LIST_TRANSFERS_REQUEST'
export const LIST_TRANSFERS_SUCCESS = 'LIST_TRANSFERS_SUCCESS'
export const LIST_TRANSFERS_ERROR = 'LIST_TRANSFERS_ERROR'
export const GET_TRANSFER_BY_ID_REQUEST = 'GET_TRANSFER_BY_ID_REQUEST'
export const GET_TRANSFER_BY_ID_SUCCESS = 'GET_TRANSFER_BY_ID_SUCCESS'
export const GET_TRANSFER_BY_ID_ERROR = 'GET_TRANSFER_BY_ID_ERROR'
export const GET_TRANSFER_SENDER_REQUEST = 'GET_TRANSFER_SENDER_REQUEST'
export const GET_TRANSFER_SENDER_SUCCESS = 'GET_TRANSFER_SENDER_SUCCESS'
export const GET_TRANSFER_SENDER_ERROR = 'GET_TRANSFER_SENDER_ERROR'
export const GET_TRANSFER_RECIPIENT_REQUEST = 'GET_TRANSFER_RECIPIENT_REQUEST'
export const GET_TRANSFER_RECIPIENT_SUCCESS = 'GET_TRANSFER_RECIPIENT_SUCCESS'
export const GET_TRANSFER_RECIPIENT_ERROR = 'GET_TRANSFER_RECIPIENT_ERROR'
export const SHOW_TRANSFER_REQUEST = 'SHOW_TRANSFER_REQUEST'
export const SHOW_TRANSFER_SUCCESS = 'SHOW_TRANSFER_SUCCESS'
export const SHOW_TRANSFER_ERROR = 'SHOW_TRANSFER_ERROR'
export const GET_TRANSFER_HISTORY_REQUEST = 'GET_TRANSFER_HISTORY_REQUEST'
export const GET_TRANSFER_HISTORY_SUCCESS = 'GET_TRANSFER_HISTORY_SUCCESS'
export const GET_TRANSFER_HISTORY_ERROR = 'GET_TRANSFER_HISTORY_ERROR'
export const CREATE_TRANSFER_REQUEST = 'CREATE_TRANSFER_REQUEST'
export const CREATE_TRANSFER_SUCCESS = 'CREATE_TRANSFER_SUCCESS'
export const CREATE_TRANSFER_ERROR = 'CREATE_TRANSFER_ERROR'
export const RESET_CREATE_TRANSFER = 'RESET_CREATE_TRANSFER'

// Export List
export const EXPORT_LIST_REQUEST = 'EXPORT_LIST_REQUEST'
export const EXPORT_LIST_SUCCESS = 'EXPORT_LIST_SUCCESS'
export const EXPORT_LIST_ERROR = 'EXPORT_LIST_ERROR'

export const CREATE_EXPORT_REQUEST = 'CREATE_EXPORT_REQUEST'
export const CREATE_EXPORT_SUCCESS = 'CREATE_EXPORT_SUCCESS'
export const CREATE_EXPORT_ERROR = 'CREATE_EXPORT_ERROR'

export const RESET_CREATE_EXPORT = 'RESET_CREATE_EXPORT'
export const RESET_WATCH_FILES = 'RESET_WATCH_FILES'

export const PROGRESS_EXPORT_SUCCESS = 'PROGRESS_EXPORT_SUCCESS'
export const DOWNLOAD_EXPORT_REQUEST = 'DOWNLOAD_EXPORT_REQUEST'
export const DOWNLOAD_EXPORT_SUCCESS = 'DOWNLOAD_EXPORT_SUCCESS'
export const DOWNLOAD_EXPORT_ERROR = 'DOWNLOAD_EXPORT_ERROR'
export const DOWNLOAD_ALL_FILES_REQUEST = 'DOWNLOAD_ALL_FILES_REQUEST'
export const DOWNLOAD_ALL_FILES_SUCCESS = 'DOWNLOAD_ALL_FILES_SUCCESS'

export const REDO_EXPORT_REQUEST = 'REDO_EXPORT_REQUEST'
export const REDO_EXPORT_SUCCESS = 'REDO_EXPORT_SUCCESS'
export const REDO_EXPORT_FAILURE = 'REDO_EXPORT_FAILURE'

export const DELETE_EXPORT_REQUEST = 'DELETE_EXPORT_REQUEST'
export const DELETE_EXPORT_SUCCESS = 'DELETE_EXPORT_SUCCESS'
export const DELETE_EXPORT_FAILURE = 'DELETE_EXPORT_FAILURE'

export const RESET_ALL_FILES_REQUEST = 'RESET_ALL_FILES_REQUEST'
export const RESET_ALL_FILES_SUCCESS = 'RESET_ALL_FILES_SUCCESS'

export const SHOW_EXPORT_REQUEST_NOTIFICATION =
  'SHOW_EXPORT_REQUEST_NOTIFICATION'
export const HIDE_EXPORT_REQUEST_NOTIFICATION =
  'HIDE_EXPORT_REQUEST_NOTIFICATION'
export const CONTROL_EXPORT_NOTIFICATION = 'CONTROL_EXPORT_NOTIFICATION'

// KPI's
export const KPI_CHANGE_PERIOD = 'KPI_CHANGE_PERIOD'

export const TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_REQUEST =
  'TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_REQUEST'
export const TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_SUCCESS =
  'TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_SUCCESS'
export const TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_ERROR =
  'TRANSACTIONS_SUCCEEDED_AMOUNT_BY_SELLER_ERROR'

export const TRANSACTIONS_SUCCEEDED_AMOUNT_ERROR =
  'TRANSACTIONS_SUCCEEDED_AMOUNT_ERROR'
export const TRANSACTIONS_SUCCEEDED_AMOUNT_REQUEST =
  'TRANSACTIONS_SUCCEEDED_AMOUNT_REQUEST'
export const TRANSACTIONS_SUCCEEDED_AMOUNT_SUCCESS =
  'TRANSACTIONS_SUCCEEDED_AMOUNT_SUCCESS'

export const TRANSACTIONS_SUCCEEDED_DETAIL_ERROR =
  'TRANSACTIONS_SUCCEEDED_DETAIL_ERROR'
export const TRANSACTIONS_SUCCEEDED_DETAIL_REQUEST =
  'TRANSACTIONS_SUCCEEDED_DETAIL_REQUEST'
export const TRANSACTIONS_SUCCEEDED_DETAIL_SUCCESS =
  'TRANSACTIONS_SUCCEEDED_DETAIL_SUCCESS'

export const TRANSFERS_SUCCEEDED_DETAIL_REQUEST =
  'TRANSFERS_SUCCEEDED_DETAIL_REQUEST'
export const TRANSFERS_SUCCEEDED_DETAIL_SUCCESS =
  'TRANSFERS_SUCCEEDED_DETAIL_SUCCESS'
export const TRANSFERS_SUCCEEDED_DETAIL_ERROR =
  'TRANSFERS_SUCCEEDED_DETAIL_ERROR'

export const TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST =
  'TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST'
export const TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS =
  'TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS'
export const TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR =
  'TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR'

export const PROGRESSION_OF_TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST =
  'PROGRESSION_OF_TRANSFERS_SUCCEEDED_FOR_PERIOD_REQUEST'
export const PROGRESSION_OF_TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS =
  'PROGRESSION_OF_TRANSFERS_SUCCEEDED_FOR_PERIOD_SUCCESS'
export const PROGRESSION_OF_TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR =
  'PROGRESSION_OF_TRANSFERS_SUCCEEDED_FOR_PERIOD_ERROR'

export const TRANSFERS_SUCCEEDED_BY_TYPE_FOR_PERIOD_REQUEST =
  'TRANSFERS_SUCCEEDED_BY_TYPE_FOR_PERIOD_REQUEST'
export const TRANSFERS_SUCCEEDED_BY_TYPE_FOR_PERIOD_SUCCESS =
  'TRANSFERS_SUCCEEDED_BY_TYPE_FOR_PERIOD_SUCCESS'
export const TRANSFERS_SUCCEEDED_BY_TYPE_FOR_PERIOD_ERROR =
  'TRANSFERS_SUCCEEDED_BY_TYPE_FOR_PERIOD_ERROR'

export const TRANSACTIONS_CHARGEDBACK_AMOUNT_REQUEST =
  'TRANSACTIONS_CHARGEDBACK_AMOUNT_REQUEST'
export const TRANSACTIONS_CHARGEDBACK_AMOUNT_SUCCESS =
  'TRANSACTIONS_CHARGEDBACK_AMOUNT_SUCCESS'
export const TRANSACTIONS_CHARGEDBACK_AMOUNT_ERROR =
  'TRANSACTIONS_CHARGEDBACK_AMOUNT_ERROR'

export const TRANSACTIONS_DISPUTED_AMOUNT_REQUEST =
  'TRANSACTIONS_DISPUTED_AMOUNT_REQUEST'
export const TRANSACTIONS_DISPUTED_AMOUNT_SUCCESS =
  'TRANSACTIONS_DISPUTED_AMOUNT_SUCCESS'
export const TRANSACTIONS_DISPUTED_AMOUNT_ERROR =
  'TRANSACTIONS_DISPUTED_AMOUNT_ERROR'

export const TRANSACTIONS_DISPUTED_DETAIL_REQUEST =
  'TRANSACTIONS_DISPUTED_DETAIL_REQUEST'
export const TRANSACTIONS_DISPUTED_DETAIL_SUCCESS =
  'TRANSACTIONS_DISPUTED_DETAIL_SUCCESS'
export const TRANSACTIONS_DISPUTED_DETAIL_ERROR =
  'TRANSACTIONS_DISPUTED_DETAIL_ERROR'

export const TRANSACTIONS_FAILED_AMOUNT_REQUEST =
  'TRANSACTIONS_FAILED_AMOUNT_REQUEST'
export const TRANSACTIONS_FAILED_AMOUNT_SUCCESS =
  'TRANSACTIONS_FAILED_AMOUNT_SUCCESS'
export const TRANSACTIONS_FAILED_AMOUNT_ERROR =
  'TRANSACTIONS_FAILED_AMOUNT_ERROR'

export const TRANSACTIONS_CHARGEDBACK_DETAIL_REQUEST =
  'TRANSACTIONS_CHARGEDBACK_DETAIL_REQUEST'
export const TRANSACTIONS_CHARGEDBACK_DETAIL_SUCCESS =
  'TRANSACTIONS_CHARGEDBACK_DETAIL_SUCCESS'
export const TRANSACTIONS_CHARGEDBACK_DETAIL_ERROR =
  'TRANSACTIONS_CHARGEDBACK_DETAIL_ERROR'

export const TRANSACTIONS_FAILED_DETAIL_REQUEST =
  'TRANSACTIONS_FAILED_DETAIL_REQUEST'
export const TRANSACTIONS_FAILED_DETAIL_SUCCESS =
  'TRANSACTIONS_FAILED_DETAIL_SUCCESS'
export const TRANSACTIONS_FAILED_DETAIL_ERROR =
  'TRANSACTIONS_FAILED_DETAIL_ERROR'

export const TRANSACTIONS_FAILED_AMOUNT_ENTRY_MODE_REQUEST =
  'TRANSACTIONS_FAILED_AMOUNT_ENTRY_MODE_REQUEST'
export const TRANSACTIONS_FAILED_AMOUNT_ENTRY_MODE_SUCCESS =
  'TRANSACTIONS_FAILED_AMOUNT_ENTRY_MODE_SUCCESS'
export const TRANSACTIONS_FAILED_AMOUNT_ENTRY_MODE_ERROR =
  'TRANSACTIONS_FAILED_AMOUNT_ENTRY_MODE_ERROR'

export const TRANSACTIONS_AVERAGE_TICKET_REQUEST =
  'TRANSACTIONS_AVERAGE_TICKET_REQUEST'
export const TRANSACTIONS_AVERAGE_TICKET_SUCCESS =
  'TRANSACTIONS_AVERAGE_TICKET_SUCCESS'
export const TRANSACTIONS_AVERAGE_TICKET_ERROR =
  'TRANSACTIONS_AVERAGE_TICKET_ERROR'

export const TRANSFERS_FAILED_REQUEST = 'TRANSFERS_FAILED_REQUEST'
export const TRANSFERS_FAILED_SUCCESS = 'TRANSFERS_FAILED_SUCCESS'
export const TRANSFERS_FAILED_ERROR = 'TRANSFERS_FAILED_ERROR'

export const TRANSFERS_FAILED_DETAIL_REQUEST = 'TRANSFERS_FAILED_DETAIL_REQUEST'
export const TRANSFERS_FAILED_DETAIL_SUCCESS = 'TRANSFERS_FAILED_DETAIL_SUCCESS'
export const TRANSFERS_FAILED_DETAIL_ERROR = 'TRANSFERS_FAILED_DETAIL_ERROR'

export const TRANSFERS_TO_THE_MARKETPLACE_REQUEST =
  'TRANSFERS_TO_THE_MARKETPLACE_REQUEST'
export const TRANSFERS_TO_THE_MARKETPLACE_SUCCESS =
  'TRANSFERS_TO_THE_MARKETPLACE_SUCCESS'
export const TRANSFERS_TO_THE_MARKETPLACE_ERROR =
  'TRANSFERS_TO_THE_MARKETPLACE_ERROR'

export const TRANSFERS_TO_THE_MARKETPLACE_DETAIL_REQUEST =
  'TRANSFERS_TO_THE_MARKETPLACE_DETAIL_REQUEST'
export const TRANSFERS_TO_THE_MARKETPLACE_DETAIL_SUCCESS =
  'TRANSFERS_TO_THE_MARKETPLACE_DETAIL_SUCCESS'
export const TRANSFERS_TO_THE_MARKETPLACE_DETAIL_ERROR =
  'TRANSFERS_TO_THE_MARKETPLACE_DETAIL_ERROR'

export const CUSTOMER_TRANSACTIONS_PROGRESSION_REQUEST =
  'CUSTOMER_TRANSACTIONS_PROGRESSION_REQUEST'
export const CUSTOMER_TRANSACTIONS_PROGRESSION_SUCCESS =
  'CUSTOMER_TRANSACTIONS_PROGRESSION_SUCCESS'
export const CUSTOMER_TRANSACTIONS_PROGRESSION_ERROR =
  'CUSTOMER_TRANSACTIONS_PROGRESSION_ERROR'

export const FUTURE_TRANSFERS_REQUEST = 'FUTURE_TRANSFERS_REQUEST'
export const FUTURE_TRANSFERS_SUCCESS = 'FUTURE_TRANSFERS_SUCCESS'
export const FUTURE_TRANSFERS_ERROR = 'FUTURE_TRANSFERS_ERROR'
export const RESET_FUTURE_TRANSFERS = 'RESET_FUTURE_TRANSFERS'
export const NEXT_FUTURE_TRANSFER_ERROR = 'NEXT_FUTURE_TRANSFER_ERROR'
export const NEXT_FUTURE_TRANSFER_REQUEST = 'NEXT_FUTURE_TRANSFER_REQUEST'
export const NEXT_FUTURE_TRANSFER_SUCCESS = 'NEXT_FUTURE_TRANSFER_SUCCESS'
export const TOTAL_FUTURE_TRANSFERS_ERROR = 'TOTAL_FUTURE_TRANSFERS_ERROR'
export const TOTAL_FUTURE_TRANSFERS_REQUEST = 'TOTAL_FUTURE_TRANSFERS_REQUEST'
export const TOTAL_FUTURE_TRANSFERS_SUCCESS = 'TOTAL_FUTURE_TRANSFERS_SUCCESS'

export const GET_FUTURE_TRANSFERS_LIST_REQUEST =
  'GET_FUTURE_TRANSFERS_LIST_REQUEST'
export const GET_FUTURE_TRANSFERS_LIST_SUCCESS =
  'GET_FUTURE_TRANSFERS_LIST_SUCCESS'
export const GET_FUTURE_TRANSFERS_LIST_ERROR = 'GET_FUTURE_TRANSFERS_LIST_ERROR'

export const SELLERS_BY_STATUS_REQUEST = 'SELLERS_BY_STATUS_REQUEST'
export const SELLERS_BY_STATUS_SUCCESS = 'SELLERS_BY_STATUS_SUCCESS'
export const SELLERS_BY_STATUS_ERROR = 'SELLERS_BY_STATUS_ERROR'

export const COMMISSION_PROGRESSION_REQUEST = 'COMMISSION_PROGRESSION_REQUEST'
export const COMMISSION_PROGRESSION_SUCCESS = 'COMMISSION_PROGRESSION_SUCCESS'
export const COMMISSION_PROGRESSION_ERROR = 'COMMISSION_PROGRESSION_ERROR'

export const ALL_TOGETHER_KPIS_REQUEST = 'ALL_TOGETHER_KPIS_REQUEST'
export const ALL_TOGETHER_KPIS_SUCCESS = 'ALL_TOGETHER_KPIS_SUCCESS'

// CUSTOM
export const GET_CUSTOM_REQUEST = 'GET_CUSTOM_REQUEST'
export const GET_CUSTOM_SUCCESS = 'GET_CUSTOM_SUCCESS'
export const GET_CUSTOM_ERROR = 'GET_CUSTOM_ERROR'
export const RESET_CUSTOM = 'RESET_CUSTOM'

export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_ERROR = 'GET_PLANS_ERROR'

export const GET_PLAN_DETAILS_REQUEST = 'GET_PLAN_DETAILS_REQUEST'
export const GET_PLAN_DETAILS_SUCCESS = 'GET_PLAN_DETAILS_SUCCESS'
export const GET_PLAN_DETAILS_ERROR = 'GET_PLAN_DETAILS_ERROR'

export const RESET_PLAN_DETAILS = 'RESET_PLAN_DETAILS'
export const RESET_PLANS = 'RESET_PLANS'
export const RESET_SELLERS_PLAN = 'RESET_SELLERS_PLAN'

export const GET_SELLERS_BY_PLAN_REQUEST = 'GET_SELLERS_BY_PLAN_REQUEST'
export const GET_SELLERS_BY_PLAN_SUCCESS = 'GET_SELLERS_BY_PLAN_SUCCESS'
export const GET_SELLERS_BY_PLAN_ERROR = 'GET_SELLERS_BY_PLAN_ERROR'

export const GET_SELLERS_BY_PLAN_INFINTY_REQUEST =
  'GET_SELLERS_BY_PLAN_INFINTY_REQUEST'
export const GET_SELLERS_BY_PLAN_INFINTY_SUCCESS =
  'GET_SELLERS_BY_PLAN_INFINTY_SUCCESS'
export const GET_SELLERS_BY_PLAN_INFINTY_ERROR =
  'GET_SELLERS_BY_PLAN_INFINTY_ERROR'

export const GET_SELLER_ACTIVE_PLAN_REQUEST = 'GET_SELLER_ACTIVE_PLAN_REQUEST'
export const GET_SELLER_ACTIVE_PLAN_SUCCESS = 'GET_SELLER_ACTIVE_PLAN_SUCCESS'
export const GET_SELLER_ACTIVE_PLAN_ERROR = 'GET_SELLER_ACTIVE_PLAN_ERROR'

export const SELLER_SUBSCRIPTIONS_REQUEST = 'SELLER_SUBSCRIPTIONS_REQUEST'
export const SELLER_SUBSCRIPTIONS_SUCCESS = 'SELLER_SUBSCRIPTIONS_SUCCESS'
export const SELLER_SUBSCRIPTIONS_ERROR = 'SELLER_SUBSCRIPTIONS_ERROR'

export const SET_SELLER_SUBSCRIPTIONS_REQUEST =
  'SET_SELLER_SUBSCRIPTIONS_REQUEST'
export const SET_SELLER_SUBSCRIPTIONS_SUCCESS =
  'SET_SELLER_SUBSCRIPTIONS_SUCCESS'
export const SET_SELLER_SUBSCRIPTIONS_ERROR = 'SET_SELLER_SUBSCRIPTIONS_ERROR'

export const REMOVE_SELLER_SUBSCRIPTIONS_REQUEST =
  'REMOVE_SELLER_SUBSCRIPTIONS_REQUEST'
export const REMOVE_SELLER_SUBSCRIPTIONS_SUCCESS =
  'REMOVE_SELLER_SUBSCRIPTIONS_SUCCESS'
export const REMOVE_SELLER_SUBSCRIPTIONS_ERROR =
  'REMOVE_SELLER_SUBSCRIPTIONS_ERROR'

export const SET_SELLER_PLAN_REQUEST = 'SET_SELLER_PLAN_REQUEST'
export const SET_SELLER_PLAN_SUCCESS = 'SET_SELLER_PLAN_SUCCESS'
export const SET_SELLER_PLAN_ERROR = 'SET_SELLER_PLAN_ERROR'
export const SET_SELLER_PLAN_RESET = 'SET_SELLER_PLAN_RESET'

export const MARKETPLACE_SELLERS_REQUEST = 'MARKETPLACE_SELLERS_REQUEST'
export const MARKETPLACE_SELLERS_SUCCESS = 'MARKETPLACE_SELLERS_SUCCESS'
export const MARKETPLACE_SELLERS_ERROR = 'MARKETPLACE_SELLERS_ERROR'

export const SEARCH_SELLERS_REQUEST = 'SEARCH_SELLERS_REQUEST'
export const SEARCH_SELLERS_SUCCESS = 'SEARCH_SELLERS_SUCCESS'
export const SEARCH_SELLERS_ERROR = 'SEARCH_SELLERS_ERROR'

export const MARKETPLACE_DETAILS_REQUEST = 'MARKETPLACE_DETAILS_REQUEST'
export const MARKETPLACE_DETAILS_SUCCESS = 'MARKETPLACE_DETAILS_SUCCESS'
export const MARKETPLACE_DETAILS_ERROR = 'MARKETPLACE_DETAILS_ERROR'

export const MARKETPLACE_TREE_REQUEST = 'MARKETPLACE_TREE_REQUEST'
export const MARKETPLACE_TREE_SUCCESS = 'MARKETPLACE_TREE_SUCCESS'
export const MARKETPLACE_TREE_ERROR = 'MARKETPLACE_TREE_ERROR'

export const MARKETPLACE_SELLER_NAVIGATE_REQUEST =
  'MARKETPLACE_SELLER_NAVIGATE_REQUEST'
export const MARKETPLACE_SELLER_NAVIGATE_SUCCESS =
  'MARKETPLACE_SELLER_NAVIGATE_SUCCESS'
export const MARKETPLACE_SELLER_NAVIGATE_ERROR =
  'MARKETPLACE_SELLER_NAVIGATE_ERROR'

export const RESET_MARKETPLACE_SELLER_SELECT = 'RESET_MARKETPLACE_SELLER_SELECT'
export const RESET_SELLER_SELECT = 'RESET_SELLER_SELECT'
export const RESET_MARKETPLACE_SELLER_UPDATE = 'RESET_MARKETPLACE_SELLER_UPDATE'

export const SELLER_DETAILS_REQUEST = 'SELLER_DETAILS_REQUEST'
export const SELLER_DETAILS_SUCCESS = 'SELLER_DETAILS_SUCCESS'
export const SELLER_DETAILS_ERROR = 'SELLER_DETAILS_ERROR'
export const SELLER_DETAILS_RESET = 'SELLER_DETAILS_RESET'

export const SELLER_BALANCE_REQUEST = 'SELLER_BALANCE_REQUEST'
export const SELLER_BALANCE_SUCCESS = 'SELLER_BALANCE_SUCCESS'
export const SELLER_BALANCE_ERROR = 'SELLER_BALANCE_ERROR'

export const RECIPIENT_SELLER_REQUEST = 'RECIPIENT_SELLER_REQUEST'
export const RECIPIENT_SELLER_SUCCESS = 'RECIPIENT_SELLER_SUCCESS'
export const RECIPIENT_SELLER_ERROR = 'RECIPIENT_SELLER_ERROR'

export const UPDATE_DEFAULT_CREDIT_REQUEST = 'UPDATE_DEFAULT_CREDIT_REQUEST'
export const UPDATE_DEFAULT_CREDIT_SUCCESS = 'UPDATE_DEFAULT_CREDIT_SUCCESS'
export const UPDATE_DEFAULT_CREDIT_ERROR = 'UPDATE_DEFAULT_CREDIT_ERROR'

export const GET_CURRENT_BALANCE_REQUEST = 'GET_CURRENT_BALANCE_REQUEST'
export const GET_CURRENT_BALANCE_SUCCESS = 'GET_CURRENT_BALANCE_SUCCESS'
export const GET_CURRENT_BALANCE_ERROR = 'GET_CURRENT_BALANCE_ERROR'

export const GET_BLOCKED_BALANCE_REQUEST = 'GET_BLOCKED_BALANCE_REQUEST'
export const GET_BLOCKED_BALANCE_SUCCESS = 'GET_BLOCKED_BALANCE_SUCCESS'
export const GET_BLOCKED_BALANCE_ERROR = 'GET_BLOCKED_BALANCE_ERROR'

export const GET_SELLER_TRANSFERS_REQUEST = 'GET_SELLER_TRANSFERS_REQUEST'
export const GET_SELLER_TRANSFERS_SUCCESS = 'GET_SELLER_TRANSFERS_SUCCESS'
export const GET_SELLER_TRANSFERS_ERROR = 'GET_SELLER_TRANSFERS_ERROR'
export const SET_CALENDAR_TRANSFERS_DATA = 'SET_CALENDAR_TRANSFERS_DATA'
export const SET_MOUNT_BOX_DETAIL = 'SET_MOUNT_BOX_DETAIL'
export const GET_SELLER_FUTURE_TRANSFERS_REQUEST_FOR_DATE =
  'GET_SELLER_FUTURE_TRANSFERS_REQUEST_FOR_DATE'
export const GET_SELLER_FUTURE_TRANSFERS_REQUEST =
  'GET_SELLER_FUTURE_TRANSFERS_REQUEST'
export const GET_SELLER_FUTURE_TRANSFERS_SUCCESS =
  'GET_SELLER_FUTURE_TRANSFERS_SUCCESS'
export const GET_SELLER_FUTURE_TRANSFERS_ERROR =
  'GET_SELLER_FUTURE_TRANSFERS_ERROR'

export const RESET_GET_SELLER_TRANSFERS = 'RESET_GET_SELLER_TRANSFERS'

export const UPDATE_MARKETPLACE_BUSINESS_REQUEST =
  'UPDATE_MARKETPLACE_BUSINESS_REQUEST'
export const UPDATE_MARKETPLACE_BUSINESS_SUCCESS =
  'UPDATE_MARKETPLACE_BUSINESS_SUCCESS'
export const UPDATE_MARKETPLACE_BUSINESS_ERROR =
  'UPDATE_MARKETPLACE_BUSINESS_ERROR'

export const UPDATE_SELLER_REQUEST = 'UPDATE_SELLER_REQUEST'
export const UPDATE_SELLER_SUCCESS = 'UPDATE_SELLER_SUCCESS'
export const UPDATE_SELLER_ERROR = 'UPDATE_SELLER_ERROR'

export const DELETE_SELLER_REQUEST = 'DELETE_SELLER_REQUEST'
export const DELETE_SELLER_SUCCESS = 'DELETE_SELLER_SUCCESS'
export const DELETE_SELLER_ERROR = 'DELETE_SELLER_ERROR'

export const ZIP_CODE_REQUEST = 'ZIP_CODE_REQUEST'
export const ZIP_CODE_SUCCESS = 'ZIP_CODE_SUCCESS'
export const ZIP_CODE_ERROR = 'ZIP_CODE_ERROR'

export const MERCHANT_CODES_REQUESTING = 'MERCHANT_CODES_REQUESTING'
export const MERCHANT_CODES_ERROR = 'MERCHANT_CODES_ERROR'
export const MERCHANT_CODES_SUCCESS = 'MERCHANT_CODES_SUCCESS'

export const SELECT_SELLER = 'SELECT_SELLER'
export const SELECT_MARKETPLACE = 'SELECT_MARKETPLACE'

export const RESET_SEARCH_SELLER = 'RESET_SEARCH_SELLER'
export const RESET_DELETE_SELLER = 'RESET_DELETE_SELLER'

// NOTIFICACOES ZNOTIFY
export const ADD_NEW_ZNOTIFY = 'ADD_NEW_ZNOTIFY'
export const REMOVE_ZNOTIFY = 'REMOVE_ZNOTIFY'
export const CLEAR_NEW_ZNOTIFY = 'CLEAR_NEW_ZNOTIFY'
export const CHECK_ITEM_ZNOTIFY = 'CHECK_ITEM_ZNOTIFY'

export const RESET_ADDRESS = 'RESET_ADDRESS'
export const RESET_CREATE_SELLER = 'RESET_CREATE_SELLER'

export const CREATE_SELLER_REQUEST = 'CREATE_SELLER_REQUEST'
export const CREATE_SELLER_SUCCESS = 'CREATE_SELLER_SUCCESS'
export const CREATE_SELLER_ERROR = 'CREATE_SELLER_ERROR'
export const FINAL_CREATE_SELLER_SUCCESS = 'FINAL_CREATE_SELLER_SUCCESS'
export const CREATE_SELLER_RESET = 'CREATE_SELLER_RESET'

export const SEND_USER_CONFIRMATION_MAIL_REQUEST =
  'SEND_USER_CONFIRMATION_MAIL_REQUEST'
export const SEND_USER_CONFIRMATION_MAIL_SUCCESS =
  'SEND_USER_CONFIRMATION_MAIL_SUCCESS'
export const SEND_USER_CONFIRMATION_MAIL_ERROR =
  'SEND_USER_CONFIRMATION_MAIL_ERROR'

export const UPDATE_CUSTOM_SLUG_REQUEST = 'UPDATE_CUSTOM_SLUG_REQUEST'
export const UPDATE_CUSTOM_SLUG_SUCCESS = 'UPDATE_CUSTOM_SLUG_SUCCESS'
export const UPDATE_CUSTOM_SLUG_ERROR = 'UPDATE_CUSTOM_SLUG_ERROR'

export const SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS'
export const SET_SELLER_PERMISSIONS = 'SET_SELLER_PERMISSIONS'
export const SET_GROUP_PERMISSIONS = 'SET_GROUP_PERMISSIONS'
export const SET_CURRENT_PERMISSIONS = 'SET_CURRENT_PERMISSIONS'

export const UPDATE_CUSTOM_COLOR_REQUEST = 'UPDATE_CUSTOM_COLOR_REQUEST'
export const UPDATE_CUSTOM_COLOR_SUCCESS = 'UPDATE_CUSTOM_COLOR_SUCCESS'
export const UPDATE_CUSTOM_COLOR_ERROR = 'UPDATE_CUSTOM_COLOR_ERROR'
export const UPDATE_TEMPORARY_COLOR = 'UPDATE_TEMPORARY_COLOR'
export const RESET_TEMPORARY_COLOR = 'RESET_TEMPORARY_COLOR'
export const RESET_CUSTOM_ERRORS = 'RESET_CUSTOM_ERRORS'

export const SEARCH_BUYER_REQUEST = 'SEARCH_BUYER_REQUEST'
export const SEARCH_BUYER_SUCCESS = 'SEARCH_BUYER_SUCCESS'
export const SEARCH_BUYER_ERROR = 'SEARCH_BUYER_ERROR'
export const RESET_SEARCH_BUYER = 'RESET_SEARCH_BUYER'

export const CREATE_BUYER_REQUEST = 'CREATE_BUYER_REQUEST'
export const CREATE_BUYER_SUCCESS = 'CREATE_BUYER_SUCCESS'
export const CREATE_BUYER_ERROR = 'CREATE_BUYER_ERROR'

export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST'
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS'
export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR'
export const RESET_CREATE_TRANSACTION = 'RESET_CREATE_TRANSACTION'
export const RESET_VOID_TRANSACTION = 'RESET_VOID_TRANSACTION'
export const RESET_CAPTURE_TRANSACTION = 'RESET_CAPTURE_TRANSACTION'

export const SEND_BOLETO_BY_EMAIL_REQUEST = 'SEND_BOLETO_BY_EMAIL_REQUEST'
export const SEND_BOLETO_BY_EMAIL_SUCCESS = 'SEND_BOLETO_BY_EMAIL_SUCCESS'
export const SEND_BOLETO_BY_EMAIL_ERROR = 'SEND_BOLETO_BY_EMAIL_ERROR'
export const RESET_SEND_BOLETO_BY_EMAIL = 'RESET_SEND_BOLETO_BY_EMAIL'

export const GET_SUPPORT_DATA_REQUEST = 'GET_SUPPORT_DATA_REQUEST'
export const GET_SUPPORT_DATA_SUCCESS = 'GET_SUPPORT_DATA_SUCCESS'
export const GET_SUPPORT_DATA_ERROR = 'GET_SUPPORT_DATA_ERROR'

export const UPDATE_SUPPORT_DATA_REQUEST = 'UPDATE_SUPPORT_DATA_REQUEST'
export const UPDATE_SUPPORT_DATA_SUCCESS = 'UPDATE_SUPPORT_DATA_SUCCESS'
export const UPDATE_SUPPORT_DATA_ERROR = 'UPDATE_SUPPORT_DATA_ERROR'

export const UPDATE_CUSTOM_COLORED_LOGO_REQUEST =
  'UPDATE_CUSTOM_COLORED_LOGO_REQUEST'
export const UPDATE_CUSTOM_COLORED_LOGO_SUCCESS =
  'UPDATE_CUSTOM_COLORED_LOGO_SUCCESS'
export const UPDATE_CUSTOM_COLORED_LOGO_ERROR =
  'UPDATE_CUSTOM_COLORED_LOGO_ERROR'
export const UPDATE_TEMPORARY_COLORED_LOGO = 'UPDATE_TEMPORARY_COLORED_LOGO'
export const RESET_TEMPORARY_COLORED_LOGO = 'RESET_TEMPORARY_COLORED_LOGO'

export const UPDATE_CUSTOM_MONOCHROME_LOGO_REQUEST =
  'UPDATE_CUSTOM_MONOCHROME_LOGO_REQUEST'
export const UPDATE_CUSTOM_MONOCHROME_LOGO_SUCCESS =
  'UPDATE_CUSTOM_MONOCHROME_LOGO_SUCCESS'
export const UPDATE_CUSTOM_MONOCHROME_LOGO_ERROR =
  'UPDATE_CUSTOM_MONOCHROME_LOGO_ERROR'
export const UPDATE_TEMPORARY_MONOCHROME_LOGO =
  'UPDATE_TEMPORARY_MONOCHROME_LOGO'
export const RESET_TEMPORARY_MONOCHROME_LOGO = 'RESET_TEMPORARY_MONOCHROME_LOGO'
export const RESET_UPDATE_LOGO = 'RESET_UPDATE_LOGO'

export const RESET_SELLERS = 'RESET_SELLERS'
export const GET_SELLERS_REQUEST = 'GET_SELLERS_REQUEST'
export const GET_SELLERS_SUCCESS = 'GET_SELLERS_SUCCESS'
export const GET_SELLERS_ERROR = 'GET_SELLERS_ERROR'

export const GET_SELLERS_SEARCH_REQUEST = 'GET_SELLERS_SEARCH_REQUEST'
export const GET_SELLERS_SEARCH_SUCCESS = 'GET_SELLERS_SEARCH_SUCCESS'
export const GET_SELLERS_SEARCH_ERROR = 'GET_SELLERS_SEARCH_ERROR'

// Webhooks
export const GET_WEBHOOKS_REQUEST = 'GET_WEBHOOKS_REQUEST'
export const GET_WEBHOOKS_SUCCESS = 'GET_WEBHOOKS_SUCCESS'
export const GET_WEBHOOKS_ERROR = 'GET_WEBHOOKS_ERROR'

export const GET_WEBHOOKS_EVENTS_REQUEST = 'GET_WEBHOOKS_EVENTS_REQUEST'
export const GET_WEBHOOKS_EVENTS_SUCCESS = 'GET_WEBHOOKS_EVENTS_SUCCESS'
export const GET_WEBHOOKS_EVENTS_ERROR = 'GET_WEBHOOKS_EVENTS_ERROR'

export const ADD_WEBHOOKS_REQUEST = 'ADD_WEBHOOKS_REQUEST'
export const ADD_WEBHOOKS_SUCCESS = 'ADD_WEBHOOKS_SUCCESS'
export const ADD_WEBHOOKS_ERROR = 'ADD_WEBHOOKS_ERROR'

export const REMOVE_WEBHOOK_REQUEST = 'REMOVE_WEBHOOK_REQUEST'
export const REMOVE_WEBHOOK_SUCCESS = 'REMOVE_WEBHOOK_SUCCESS'
export const REMOVE_WEBHOOK_ERROR = 'REMOVE_WEBHOOK_ERROR'

export const RESET_WEBHOOK = 'RESET_WEBHOOK'

// Seller
export const GET_SELLER_DETAILS_REQUEST = 'SELLER_DETAILS_REQUEST'
export const GET_SELLER_DETAILS_SUCCESS = 'SELLER_DETAILS_SUCCESS'
export const GET_SELLER_DETAILS_ERROR = 'SELLER_DETAILS_ERROR'
export const RESET_SELLER_DETAIL = 'RESET_SELLER_DETAIL'
export const UPDATE_SELLER_DETAILS_REQUEST = 'UPDATE_SELLER_DETAILS_REQUEST'
export const UPDATE_SELLER_DETAILS_SUCCESS = 'UPDATE_SELLER_DETAILS_SUCCESS'
export const UPDATE_SELLER_DETAILS_ERROR = 'UPDATE_SELLER_DETAILS_ERROR'

export const UPDATE_SELLER_OWNER_DETAILS_REQUEST =
  'UPDATE_SELLER_OWNER_DETAILS_REQUEST'
export const UPDATE_SELLER_OWNER_DETAILS_SUCCESS =
  'UPDATE_SELLER_OWNER_DETAILS_SUCCESS'
export const UPDATE_SELLER_OWNER_DETAILS_ERROR =
  'UPDATE_SELLER_OWNER_DETAILS_ERROR'

export const FAVORITE_SELLERS_REQUEST = 'FAVORITE_SELLERS_REQUEST'
export const FAVORITE_SELLERS_SUCCESS = 'FAVORITE_SELLERS_SUCCESS'
export const FAVORITE_SELLERS_ERROR = 'FAVORITE_SELLERS_ERROR'
export const UPDATE_FAVORITE_SELLERS = 'UPDATE_FAVORITE_SELLERS'

export const FAVORITE_MARKETPLACES_REQUEST = 'FAVORITE_MARKETPLACES_REQUEST'
export const FAVORITE_MARKETPLACES_SUCCESS = 'FAVORITE_MARKETPLACES_SUCCESS'
export const FAVORITE_MARKETPLACES_ERROR = 'FAVORITE_MARKETPLACES_ERROR'
export const UPDATE_FAVORITE_MARKETPLACES = 'UPDATE_FAVORITE_MARKETPLACES'

// Receivables
export const GET_RECEIVABLES_REQUEST = 'GET_RECEIVABLES_REQUEST'
export const GET_RECEIVABLES_SUCCESS = 'GET_RECEIVABLES_SUCCESS'
export const GET_RECEIVABLES_ERROR = 'GET_RECEIVABLES_ERROR'

export const GET_RECEIPT_REQUEST = 'GET_RECEIPT_REQUEST'
export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS'
export const GET_RECEIPT_ERROR = 'GET_RECEIPT_ERROR'

export const SEND_RECEIPT_REQUEST = 'SEND_RECEIPT_REQUEST'
export const SEND_RECEIPT_SUCCESS = 'SEND_RECEIPT_SUCCESS'
export const SEND_RECEIPT_ERROR = 'SEND_RECEIPT_ERROR'

export const RESET_RECEIPT = 'RESET_RECEIPT'

export const RESET_SEND_RECEIPT = 'RESET_SEND_RECEIPT'

export const PAIR_POS_REQUEST = 'PAIR_POS_REQUEST'
export const PAIR_POS_SUCCESS = 'PAIR_POS_SUCCESS'
export const PAIR_POS_ERROR = 'PAIR_POS_ERROR'

export const GET_POS_SELLER_REQUEST = 'GET_POS_SELLER_REQUEST'
export const GET_POS_SELLER_SUCCESS = 'GET_POS_SELLER_SUCCESS'
export const GET_POS_SELLER_ERROR = 'GET_POS_SELLER_ERROR'

export const RESET_POS = 'RESET_POS'

export const GET_SELLER_DOCUMENTS_REQUEST = 'GET_SELLER_DOCUMENTS_REQUEST'
export const GET_SELLER_DOCUMENTS_SUCCESS = 'GET_SELLER_DOCUMENTS_SUCCESS'
export const GET_SELLER_DOCUMENTS_ERROR = 'GET_SELLER_DOCUMENTS_ERROR'

export const UPLOAD_SELLER_DOCUMENT_REQUEST = 'UPLOAD_SELLER_DOCUMENT_REQUEST'
export const UPLOAD_SELLER_DOCUMENT_SUCCESS = 'UPLOAD_SELLER_DOCUMENT_SUCCESS'
export const UPLOAD_SELLER_DOCUMENT_ERROR = 'UPLOAD_SELLER_DOCUMENT_ERROR'

export const RESET_UPLOAD_DOCUMENTS = 'RESET_UPLOAD_DOCUMENTS'

export const GET_TRANSFER_SELLER_REQUEST = 'GET_TRANSFER_SELLER_REQUEST'
export const GET_TRANSFER_SELLER_SUCCESS = 'GET_TRANSFER_SELLER_SUCCESS'
export const GET_TRANSFER_SELLER_ERROR = 'GET_TRANSFER_SELLER_ERROR'

export const RESET_SEARCH_TRANSFER_SELLER = 'RESET_SEARCH_TRANSFER_SELLER'

export const GET_BANK_ACCOUNTS_REQUEST = 'GET_BANK_ACCOUNTS_REQUEST'
export const GET_BANK_ACCOUNTS_SUCCESS = 'GET_BANK_ACCOUNTS_SUCCESS'
export const GET_BANK_ACCOUNTS_ERROR = 'GET_BANK_ACCOUNTS_ERROR'

export const GET_BALANCES_REQUEST = 'GET_BALANCES_REQUEST'
export const GET_BALANCES_FILTERS = 'GET_BALANCES_FILTERS'
export const GET_BALANCES_SUCCESS = 'GET_BALANCES_SUCCESS'
export const GET_BALANCES_ERROR = 'GET_BALANCES_ERROR'
export const RESET_BALANCES = 'RESET_BALANCES'

export const GET_API_KEYS_REQUEST = 'GET_API_KEYS_REQUEST'
export const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS'
export const GET_API_KEYS_ERROR = 'GET_API_KEYS_ERROR'

export const CREATE_API_KEYS_REQUEST = 'CREATE_API_KEYS_REQUEST'
export const CREATE_API_KEYS_SUCCESS = 'CREATE_API_KEYS_SUCCESS'
export const CREATE_API_KEYS_ERROR = 'CREATE_API_KEYS_ERROR'

// Register status
export const REGISTER_STATUS_REQUEST = 'REGISTER_STATUS_REQUEST'
export const REGISTER_STATUS_SUCCESS = 'REGISTER_STATUS_SUCCESS'
export const REGISTER_STATUS_ERROR = 'REGISTER_STATUS_ERROR'

export const SELLER_CONTEXT_CHANGE_REQUEST = 'SELLER_CONTEXT_CHANGE_REQUEST'
export const SELLER_CONTEXT_CHANGE_SUCCESS = 'SELLER_CONTEXT_CHANGE_SUCCESS'
export const SELLER_CONTEXT_CHANGE_ERROR = 'SELLER_CONTEXT_CHANGE_ERROR'
export const RESET_SELLER_CONTEXT = 'RESET_SELLER_CONTEXT'
export const MARKETPLACE_CONTEXT_CHANGE_REQUEST =
  'MARKETPLACE_CONTEXT_CHANGE_REQUEST'
export const MARKETPLACE_CONTEXT_CHANGE_SUCCESS =
  'MARKETPLACE_CONTEXT_CHANGE_SUCCESS'
export const MARKETPLACE_CONTEXT_CHANGE_ERROR =
  'MARKETPLACE_CONTEXT_CHANGE_ERROR'

// COMMISSIONS
export const GET_LAST_COMMISSION_REQUEST = 'GET_LAST_COMMISSION_REQUEST'
export const GET_LAST_COMMISSION_SUCCESS = 'GET_LAST_COMMISSION_SUCCESS'
export const GET_LAST_COMMISSION_ERROR = 'GET_LAST_COMMISSION_ERROR'

export const GET_COMMISSIONS_REQUEST = 'GET_COMMISSIONS_REQUEST'
export const GET_COMMISSIONS_SUCCESS = 'GET_COMMISSIONS_SUCCESS'
export const GET_COMMISSIONS_ERROR = 'GET_COMMISSIONS_ERROR'

export const ONBOARDING_VIEW_CHECK = 'ONBOARDING_VIEW_CHECK'
export const HINT_VIEW_CHECK_REQUEST = 'HINT_VIEW_CHECK_REQUEST'
export const HINT_VIEW_CHECK_SUCCESS = 'HINT_VIEW_CHECK_SUCCESS'
export const HINT_VIEW_CHECK_ERROR = 'HINT_VIEW_CHECK_ERROR'

// BILLING PAYMENTS
export const GET_BILLING_PAYMENT_REQUEST = 'GET_BILLING_PAYMENT_REQUEST'
export const GET_BILLING_PAYMENT_SUCCESS = 'GET_BILLING_PAYMENT_SUCCESS'
export const GET_BILLING_PAYMENT_ERROR = 'GET_BILLING_PAYMENT_ERROR'
