export const transfersTypeLabels = {
  'transfer': { label: 'Entre contas digitais' },
  'payout_automatic': { label: 'Automática' },
  'payout_manual': { label: 'Enviada' },
  'cash_in': { label: 'Recebida' }
}

export function getTransfersTypeLabels (type) {
  return transfersTypeLabels[type] ? transfersTypeLabels[type].label : ''
}
