export const ProfileTypes = [
  {
    label: 'Administrador',
    value: 'full_access_admin',
    description: 'Acesso completo ao dashboard, possui todas as permissões de visualização e ação em todas as áreas.',
    operational: {
      sales: {
        label: 'Vendas',
        visualization: true,
        action: true
      },
      transfers: {
        label: 'Transferências',
        visualization: true,
        action: true
      },
      sellers: {
        label: 'Estabelecimentos',
        visualization: true,
        action: true
      },
      exported_lists: {
        label: 'Relatórios',
        visualization: true,
        action: true
      },
      commission: {
        label: 'Relatórios de Markup',
        visualization: true,
        action: true
      }
    },
    configuration: {
      register: {
        label: 'Cadastro',
        visualization: true,
        action: true
      },
      transfers: {
        label: 'Meios de Recebimento',
        visualization: true,
        action: true
      },
      users: {
        label: 'Usuários',
        visualization: true,
        action: true
      },
      api_keys: {
        label: 'API Keys',
        visualization: true,
        action: true
      },
      webhooks: {
        label: 'Webhooks',
        visualization: true,
        action: true
      },
      plans: {
        label: 'Planos de Venda',
        visualization: true,
        action: true
      },
      customize: {
        label: 'Personalização',
        visualization: true,
        action: true
      }
    }
  },
  {
    label: 'Operações',
    value: 'full_access_operation',
    description: 'Possui todas as permissões de visualização e ação na área de operações.',
    operational: {
      sales: {
        label: 'Vendas',
        visualization: true,
        action: true
      },
      transfers: {
        label: 'Transferências',
        visualization: true,
        action: true
      },
      sellers: {
        label: 'Estabelecimentos',
        visualization: true,
        action: false
      },
      exported_lists: {
        label: 'Relatórios',
        visualization: true,
        action: true
      },
      commission: {
        label: 'Relatórios de Markup',
        visualization: false,
        action: false
      }
    },
    configuration: {
      register: {
        label: 'Cadastro',
        visualization: false,
        action: false
      },
      transfers: {
        label: 'Meios de Recebimento',
        visualization: false,
        action: false
      },
      users: {
        label: 'Usuários',
        visualization: false,
        action: false
      },
      api_keys: {
        label: 'API Keys',
        visualization: false,
        action: false
      },
      webhooks: {
        label: 'Webhooks',
        visualization: false,
        action: false
      },
      plans: {
        label: 'Planos de Venda',
        visualization: false,
        action: false
      },
      customize: {
        label: 'Personalização',
        visualization: false,
        action: false
      }
    }
  },
  {
    label: 'Configurações',
    value: 'full_access_configuration',
    description: 'Possui todas as permissões de visualização e ação na área de configurações.',
    operational: {
      sales: {
        label: 'Vendas',
        visualization: false,
        action: false
      },
      transfers: {
        label: 'Transferências',
        visualization: false,
        action: false
      },
      sellers: {
        label: 'Estabelecimentos',
        visualization: true,
        action: true
      },
      exported_lists: {
        label: 'Relatórios',
        visualization: false,
        action: false
      },
      commission: {
        label: 'Relatórios de Markup',
        visualization: false,
        action: false
      }
    },
    configuration: {
      register: {
        label: 'Cadastro',
        visualization: true,
        action: true
      },
      transfers: {
        label: 'Meios de Recebimento',
        visualization: true,
        action: true
      },
      users: {
        label: 'Usuários',
        visualization: true,
        action: true
      },
      api_keys: {
        label: 'API Keys',
        visualization: true,
        action: true
      },
      webhooks: {
        label: 'Webhooks',
        visualization: true,
        action: true
      },
      plans: {
        label: 'Planos de Venda',
        visualization: true,
        action: true
      },
      customize: {
        label: 'Personalização',
        visualization: true,
        action: true
      }
    }
  },
  {
    label: 'Visualização',
    value: 'full_visualization',
    description: 'Possui apenas as permissões de visualização nas áreas de operações e de configurações.',
    operational: {
      sales: {
        label: 'Vendas',
        visualization: true,
        action: false
      },
      transfers: {
        label: 'Transferências',
        visualization: true,
        action: false
      },
      sellers: {
        label: 'Estabelecimentos',
        visualization: true,
        action: false
      },
      exported_lists: {
        label: 'Relatórios',
        visualization: false,
        action: false
      },
      commission: {
        label: 'Relatórios de Markup',
        visualization: false,
        action: false
      }
    },
    configuration: {
      register: {
        label: 'Cadastro',
        visualization: true,
        action: false
      },
      transfers: {
        label: 'Meios de Recebimento',
        visualization: true,
        action: false
      },
      users: {
        label: 'Usuários',
        visualization: true,
        action: false
      },
      api_keys: {
        label: 'API Keys',
        visualization: true,
        action: false
      },
      webhooks: {
        label: 'Webhooks',
        visualization: true,
        action: false
      },
      plans: {
        label: 'Planos de Venda',
        visualization: true,
        action: false
      },
      customize: {
        label: 'Personalização',
        visualization: true,
        action: false
      }
    }
  }
]
