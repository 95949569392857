import './Styles/Status.scss'

import { gray, green, yellow, red } from 'src/Styles/settings/Constants'

export const statusLabels = {
  'requested': { label: 'Processando simulação', color: yellow },
  'simulated': { label: 'Simulação disponível', color: yellow },
  'ready': { label: 'Em análise', color: yellow },
  'approved': { label: 'Aprovada', color: green },
  'succeeded': { label: 'Antecipada', color: green },
  'canceled': { label: 'Cancelada', color: red },
  'expired': { label: 'Expirada', color: red },
  'invalid': { label: 'Inválida', color: red },
  'rejected': { label: 'Rejeitada', color: red }
}

export function getStatusLabel (status) {
  return statusLabels[status] ? statusLabels[status] : { label: status, color: gray }
}
