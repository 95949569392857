export const transferTypes = {
  'adjustment': { label: 'Ajuste', iconSrc: '/assets/icons/banking/transfer.svg' },
  'cash_in': { label: 'Transferência Recebida', iconSrc: '/assets/icons/banking/transfer-receive.svg' },
  'cash_out': { label: 'Transferência Para Terceiros', iconSrc: '/assets/icons/banking/transfer.svg' },
  'transfer_and_payout': { label: 'Transferência Para Terceiros', iconSrc: '/assets/icons/banking/transfer.svg' },
  'payout_manual': { label: 'Transferência Enviada', iconSrc: '/assets/icons/banking/transfer-send.svg' },
  'payout_prepaid_card': { label: 'Transferência Enviada', iconSrc: '/assets/icons/banking/transfer-send.svg' },
  'payout_automatic': { label: 'Transferência Automática', iconSrc: '/assets/icons/banking/transfer-send.svg' },
  'cash_out_automatic': { label: 'Transferência Automática', iconSrc: '/assets/icons/banking/transfer-send.svg' },
  'transfer': { label: 'Transferência Entre Contas Digitais', iconSrc: '/assets/icons/banking/transfer.svg' }
}
